<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Categoria da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="LeadChangeCategory"
        class="p-col-12"
        @submit.prevent="setCategory"
        style="min-height: 15vh"
      >
        <p>
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="leadCategory"
                :options="categoriesList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('leadCategory') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="leadCategory">Selecionar Categoria da Lead</label>
            </span>
            <small
              v-if="errors.has('leadCategory')"
              class="p-error"
              role="alert"
            >
              Categoria é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setCategory"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
export default {
  name: "ChangeCategory",
  props: ["showToast", "show", "categoryId", "proposalId", "proposalSubject"],
  watch: {
    categoryId() {
      this.$validator.pause();
      this.$validator.reset();
      this.current = this.categoryId;
    },
  },
  data() {
    return {
      current: this.categoryId,
      categoriesList: [],
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      return crmService
        .getProposalsCategories()
        .then((response) => (this.categoriesList = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    setCategory() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return crmService
          .setCategory(this.proposalId, { category_id: parseInt(this.current) })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar a categoria da lead",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedLeadCategory", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Categoria da lead alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedLeadCategory", {
              status: "ok",
              result: response,
            });
          });
      });
    },
  },
};
</script>
