<template>
  <div>
    <Dialog
      :visible.sync="emailCompose.show"
      :containerStyle="{ width: $isMobile() ? '100vw' : '40vw' }"
      :position="'bottomright'"
      :maximizable="true"
      v-on:hide="cancelSend"
      :header="emailCompose.header"
    >
      <div class="p-col-12">
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>Para</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              v-bind:class="[
                {
                  'p-invalid': emailCompose.response.errorTo,
                },
                'p-ml-1',
              ]"
              separator=","
              v-model="emailCompose.response.to"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
            <small
              v-if="emailCompose.response.errorTo"
              class="p-error"
              role="alert"
            >
              Destinatário está vazio
            </small>
          </div>
        </div>
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-col-1">
            <b>CC</b>
          </div>
          <div class="p-col-11">
            <Chips
              :allowDuplicate="false"
              style="width: 100%"
              separator=","
              v-model="emailCompose.response.cc"
            >
              <template #chip="slotProps">
                <div class="p-d-flex p-ai-center">
                  <i class="pi pi-user p-mr-1 circle"></i>
                  <span>{{ slotProps.value }}</span>
                </div>
              </template>
            </Chips>
          </div>
        </div>
        <div class="p-col-12">
          <Editor
            name="body"
            v-model="emailCompose.response.content"
            editorStyle="height: 200px"
            v-bind:class="[
              { 'p-invalid': emailCompose.response.errorContent },
              'form-control',
            ]"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
              </span>
            </template>
          </Editor>
          <small
            class="p-error"
            role="alert"
            v-if="emailCompose.response.errorContent"
          >
            O email está vazio
          </small>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <FileUpload
            :ref="'attachmentInput'"
            :name="'attachments[]'"
            chooseLabel="Adicionar Anexo"
            :multiple="true"
            :showUploadButton="false"
            :showCancelButton="false"
            :maxFileSize="2000000000"
          />
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Enviar'"
          icon="pi pi-send"
          class="p-button-sm p-button-success"
          @click="sendEmail()"
        />
        <Button
          :label="'Cancelar'"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          @click="cancelSend()"
        />
      </template>
    </Dialog>
    <div
      class="p-col-12 card p-shadow-3"
      :style="{ position: 'relative', height: '80vh' }"
      v-if="!proposalInfo"
    >
      <Loading :active="true" :isFullPage="false"></Loading>
    </div>
    <div class="p-grid p-col-12" v-else :style="{ position: 'relative' }">
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Loading :active.sync="loading" :isFullPage="false"></Loading>
      <ConfirmDialog />
      <ChangePriority
        :showToast="true"
        :show="setPriority"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.cleanSubject"
        :priorityId="proposalInfo.priority"
        v-on:cancel="setPriority = false"
        v-on:changedProposalPriority="changePriorityOk"
      />
      <ChangeCategory
        :showToast="true"
        :show="setCategory"
        :proposalId="proposalInfo.id"
        :proposalSubject="proposalInfo.cleanSubject"
        :categoryId="proposalInfo.category"
        v-on:cancel="setCategory = false"
        v-on:changedLeadCategory="changeCategoryOk"
      />
      <AddClientContact
        :showToast="true"
        :show="addClientContact"
        :clientId="proposalInfo.client_id"
        v-on:cancel="addClientContact = false"
        v-on:contactAdded="contactAddedOk"
      />
      <AddNote
        :showToast="true"
        :show="addNote"
        :proposalId="proposalInfo.id"
        v-on:cancel="addNote = false"
        v-on:addedCrmNote="noteAdded"
      />
      <ImportProposal
        :showToast="true"
        :show="importProposal"
        :leadId="proposalInfo.id"
        :leadSubject="proposalInfo.cleanSubject"
        v-on:cancel="importProposal = false"
        v-on:importedProposal="importProposalOk"
      />
      <div class="p-col-12 card p-shadow-3 p-d-flex p-ai-center">
        <h3>Lead {{ proposalInfo.id }} | {{ proposalInfo.cleanSubject }}</h3>
      </div>
      <div
        v-bind:class="[
          { 'p-md-12': proposalId },
          { 'p-md-6': !proposalId },
          'p-col-12',
        ]"
      >
        <div class="p-col-12 card p-shadow-3">
          <div class="p-d-flex p-flex-wrap">
            <Button
              v-if="proposalInfo.origin == 'proposal'"
              label="Ir para a Proposta"
              class="p-button-sm p-mt-1 p-mr-1"
              @click="goProposal()"
            />
            <Button
              v-if="proposalInfo.origin == 'survey'"
              label="Ir para o Survey"
              class="p-button-sm p-mt-1 p-mr-1"
              @click="goSurvey()"
            />
          </div>
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label
                for="status"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Referência Interna</label
              >
              <div class="p-col-12 p-md-9">
                <InputText
                  name="internalReference"
                  :value="'W4M_' + proposalInfo.id + ' | '"
                  v-bind:class="[
                    'form-control',
                  ]"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="status"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Assunto</label
              >
              <div class="p-col-12 p-md-9">
                <InputText
                  name="subject"
                  v-model="proposalInfo.cleanSubject"
                  v-validate="'required'"
                  v-bind:class="[
                    { 'p-invalid': errors.has('subject') },
                    'form-control',
                  ]"
                  @change="hasChange = true"
                />
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="status"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Estado</label
              >
              <div class="p-col-12 p-md-9">
                <Dropdown
                  name="status"
                  v-model="proposalInfo.status"
                  placeholder="Selecione o Estado da Lead"
                  :options="statusList"
                  optionValue="id"
                  optionLabel="name"
                  class="p-column-filter"
                  :filter="true"
                  :style="{
                    width: '100%',
                  }"
                  @change="hasChange = true"
                >
                </Dropdown>
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="next_contact"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Próximo Contacto</label
              >
              <div class="p-col-12 p-md-9">
                <v-date-picker
                  v-model="proposalInfo.next_contact"
                  :name="'next_contact_picker'"
                  :id="'next_contact_picker'"
                  :min-date="new Date()"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  mode="dateTime"
                  is24hr
                  @input="hasChange = true"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'next_contact'"
                        :id="'next_contact'"
                        v-bind:class="[
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        :style="{
                          width: '100%',
                        }"
                      />
                    </span>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="next_contact"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Tipo de Cliente
                <i
                  v-if="!proposalInfo.client_type"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <Dropdown
                  name="client_type"
                  v-model="proposalInfo.client_type"
                  placeholder="Selecione o Tipo de Cliente"
                  :options="clientTypeList"
                  optionValue="id"
                  optionLabel="name"
                  class="p-column-filter"
                  :filter="true"
                  :style="{
                    width: '100%',
                  }"
                  @change="hasChange = true"
                >
                </Dropdown>
              </div>
            </div>

            <div class="p-field p-grid" v-if="proposalInfo.client_type > 1">
              <label
                for="contact_id"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Parceiro Contacto
                <i
                  v-if="!proposalInfo.contact_id"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <Dropdown
                  name="contact_id"
                  v-model="proposalInfo.contact_id"
                  placeholder="Selecione o Contacto do Parceiro"
                  :options="contactsList"
                  optionValue="id"
                  dataKey="reference"
                  :optionLabel="contactsListLabel"
                  class="p-column-filter"
                  :filter="true"
                  @change="hasChange = true"
                >
                </Dropdown>
                <Button
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-text p-button-success"
                  v-tooltip="'Adicionar Contacto de Cliente'"
                  @click="addClientContact = true"
                />
              </div>
            </div>
            <div v-if="proposalInfo.client_type == 1">
              <div class="p-field p-grid">
                <label
                  for="potential_client_name"
                  class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                  >Potencial Cliente
                  <i
                    v-if="!proposalInfo.potential_client_name"
                    class="pi pi-exclamation-triangle p-error"
                  ></i
                ></label>
                <div class="p-col-12 p-md-9">
                  <inputText
                    name="potential_client_name"
                    v-model="proposalInfo.potential_client_name"
                    placeholder="Nome do Potencial Cliente"
                    class="p-column-filter"
                    :style="{
                      width: '100%',
                    }"
                    @change="hasChange = true"
                  />
                </div>
              </div>
              <div class="p-field p-grid">
                <label
                  for="next_contact"
                  class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                  >Representante<i
                    v-if="!proposalInfo.potential_client_person"
                    class="pi pi-exclamation-triangle p-error"
                  ></i
                ></label>
                <div class="p-col-12 p-md-9">
                  <inputText
                    name="potential_client_person"
                    v-model="proposalInfo.potential_client_person"
                    placeholder="Nome do Representande do Potencial Cliente"
                    class="p-column-filter"
                    :style="{
                      width: '100%',
                    }"
                    @change="hasChange = true"
                  />
                </div>
              </div>

              <div class="p-field p-grid">
                <label
                  for="potential_client_telephone"
                  class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                  >Telefone<i
                    v-if="!proposalInfo.potential_client_telephone"
                    class="pi pi-exclamation-triangle p-error"
                  ></i
                ></label>
                <div class="p-col-12 p-md-9">
                  <inputText
                    name="potential_client_telephone"
                    v-model="proposalInfo.potential_client_telephone"
                    placeholder="Telefone do Representande do Potencial Cliente"
                    class="p-column-filter"
                    :style="{
                      width: '100%',
                    }"
                    @change="hasChange = true"
                  />
                </div>
              </div>

              <div class="p-field p-grid">
                <label
                  for="potential_client_email"
                  class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                  >Email<i
                    v-if="!proposalInfo.potential_client_email"
                    class="pi pi-exclamation-triangle p-error"
                  ></i
                ></label>
                <div class="p-col-12 p-md-9">
                  <inputText
                    name="potential_client_email"
                    v-model="proposalInfo.potential_client_email"
                    placeholder="Email do Representande do Potencial Cliente"
                    class="p-column-filter"
                    :style="{
                      width: '100%',
                    }"
                    @change="hasChange = true"
                  />
                </div>
              </div>
              <div class="p-field p-grid">
                <label
                  for="potential_client_comments"
                  class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                  >Potencial Cliente Comentários</label
                >
                <div class="p-col-12 p-md-9">
                  <Textarea
                    name="potential_client_comments"
                    :autoResize="true"
                    v-model="proposalInfo.potential_client_comments"
                    placeholder="Comentários do Potencial Cliente"
                    :style="{
                      width: '100%',
                    }"
                    v-bind:class="[
                      'p-inputtextarea',
                      'p-inputtext',
                      'p-component',
                    ]"
                    rows="5"
                    @input="hasChange = true"
                  />
                </div>
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="seller_id"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Vendedor</label
              >
              <div class="p-col-12 p-md-9">
                <Dropdown
                  name="seller_id"
                  v-model="proposalInfo.seller_id"
                  placeholder="Selecione o Vendedor"
                  :options="sellersList"
                  optionValue="id"
                  dataKey="id"
                  :optionLabel="searchLabelName"
                  class="p-column-filter"
                  :filter="true"
                  :style="{
                    width: '100%',
                  }"
                  @change="hasChange = true"
                >
                </Dropdown>
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="description"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Descrição</label
              >
              <div class="p-col-12 p-md-9">
                <Textarea
                  name="description"
                  :autoResize="true"
                  v-model="proposalInfo.description"
                  :style="{
                    width: '100%',
                  }"
                  v-bind:class="[
                    'p-inputtextarea',
                    'p-inputtext',
                    'p-component',
                  ]"
                  rows="8"
                  @input="hasChange = true"
                />
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="competitors"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Concorrência</label
              >
              <div class="p-col-12 p-md-9">
                <Textarea
                  name="competitors"
                  :autoResize="true"
                  v-model="proposalInfo.competitors"
                  :style="{
                    width: '100%',
                  }"
                  v-bind:class="[
                    'p-inputtextarea',
                    'p-inputtext',
                    'p-component',
                  ]"
                  rows="5"
                  @input="hasChange = true"
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="priority"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Prioridade</label
              >
              <div class="p-col-12 p-md-9">
                <span class="clickable-item" @click="setPriority = true">
                  {{ proposalInfo.proposal_priority.name }}
                </span>
              </div>
            </div>
            <div class="p-field p-grid">
              <label
                for="category"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Categoria<i
                  v-if="!proposalInfo.category"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <span
                  v-bind:class="[
                    { error: !proposalInfo.category },
                    'clickable-item',
                  ]"
                  @click="setCategory = true"
                >
                  {{
                    proposalInfo.category
                      ? proposalInfo.proposal_category.name
                      : "(Sem Categoria Associada)"
                  }}
                </span>
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="sale_value"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Valor Venda<i
                  v-if="!proposalInfo.sale_value"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <InputNumber
                  name="sale_value"
                  mode="currency"
                  currency="EUR"
                  locale="pt-PT"
                  v-model="proposalInfo.sale_value"
                  class="p-column-filter"
                  :style="{
                    width: '100%',
                  }"
                  @input="hasChange = true"
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="next_contact"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Valor Mensalidade<i
                  v-if="!proposalInfo.monthly_sales_value"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <InputNumber
                  mode="currency"
                  currency="EUR"
                  locale="pt-PT"
                  v-model="proposalInfo.monthly_sales_value"
                  class="p-column-filter"
                  :style="{
                    width: '100%',
                  }"
                  @input="hasChange = true"
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="cost"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Preço Custo<i
                  v-if="!proposalInfo.cost"
                  class="pi pi-exclamation-triangle p-error"
                ></i
              ></label>
              <div class="p-col-12 p-md-9">
                <InputNumber
                  name="cost"
                  mode="currency"
                  currency="EUR"
                  locale="pt-PT"
                  v-model="proposalInfo.cost"
                  class="p-column-filter"
                  :style="{
                    width: '100%',
                  }"
                  @input="hasChange = true"
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="proposal_date_picker"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Data Proposta</label
              >
              <div class="p-col-12 p-md-9">
                <v-date-picker
                  v-model="proposalInfo.proposal_date"
                  :name="'proposal_date_picker'"
                  :id="'proposal_date_picker'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  @input="hasChange = true"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'proposal_date'"
                        :id="'proposal_date'"
                        v-bind:class="[
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        :style="{
                          width: '100%',
                        }"
                      />
                    </span>
                  </template>
                </v-date-picker>
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="status"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Probabilidade de Fecho</label
              >
              <div class="p-col-12 p-md-9">
                <Dropdown
                  name="status"
                  v-model="proposalInfo.closing_probability"
                  placeholder="Selecione a Probabilidade de Fecho"
                  :options="closingProbabilityList"
                  optionValue="value"
                  optionLabel="label"
                  class="p-column-filter"
                  :filter="true"
                  :style="{
                    width: '100%',
                  }"
                  @change="hasChange = true"
                >
                </Dropdown>
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="expected_sale_date"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Data Prevista da Venda</label
              >
              <div class="p-col-12 p-md-9">
                <v-date-picker
                  v-model="proposalInfo.expected_sale_date"
                  :name="'expected_sale_date_picker'"
                  :id="'expected_sale_date_picker'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  @input="hasChange = true"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'expected_sale_date'"
                        :id="'expected_sale_date'"
                        v-bind:class="[
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        :style="{
                          width: '100%',
                        }"
                      />
                    </span>
                  </template>
                </v-date-picker>
              </div>
            </div>

            <div class="p-field p-grid">
              <label
                for="expected_installation_date"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Data Prevista da Instalação</label
              >
              <div class="p-col-12 p-md-9">
                <v-date-picker
                  v-model="proposalInfo.expected_installation_date"
                  :name="'expected_installation_date_picker'"
                  :id="'expected_installation_date_picker'"
                  :masks="{
                    input: 'YYYY-MM-DD',
                  }"
                  @input="hasChange = true"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <span class="p-float-label">
                      <input
                        :name="'expected_installation_date'"
                        :id="'expected_installation_date'"
                        v-bind:class="[
                          'form-control',
                          'p-inputtext',
                          'p-component',
                          'p-filled',
                        ]"
                        :value="inputValue"
                        v-on="inputEvents"
                        :style="{
                          width: '100%',
                        }"
                      />
                    </span>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="p-field p-grid" v-if="proposalInfo.created_at">
              <label
                for="next_contact"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Criado</label
              >
              <div class="p-col-12 p-md-9">
                <span>
                  {{
                    proposalInfo.created_by == 0
                      ? "Intranet"
                      : proposalInfo.proposal_created_user.username
                  }}
                  | {{ getDateTime(proposalInfo.created_at) }} |
                  {{ proposalInfo.createdDaysAgo }} dias
                </span>
              </div>
            </div>

            <div class="p-field p-grid" v-if="proposalInfo.updated_at">
              <label
                for="next_contact"
                class="p-col-12 p-mb-2 p-md-3 p-mb-md-0 p-text-bold"
                >Atualizado</label
              >
              <div class="p-col-12 p-md-9">
                <span
                  v-if="
                    proposalInfo.updated_at &&
                    proposalInfo.updated_at != proposalInfo.created_at
                  "
                >
                  {{
                    proposalInfo.updated_by == 0
                      ? "Intranet"
                      : proposalInfo.proposal_updated_user.username
                  }}
                  | {{ getDateTime(proposalInfo.updated_at) }}
                </span>
              </div>
            </div>
          </div>
          <divider />
          <Button
            v-if="hasChange"
            icon="pi pi-save"
            label="Gravar"
            class="p-button-sm p-button-success p-mr-1 p-mt-1"
            @click="saveProposal()"
          />
          <Button
            v-tooltip="'Importar outras leads para esta'"
            label="Importar Leads"
            icon="pi pi-directions-alt"
            class="p-button-sm p-button-warning p-mt-1"
            @click="importProposal = true"
          />
        </div>
        <div class="p-col-12 card p-shadow-3">
          <Accordion :multiple="true">
            <AccordionTab class="accordion-custom">
              <template #header>
                <div style="font-size: large">
                  <i class="pi pi-comments p-mr-2"></i>Notas
                </div>
                <Badge
                  v-badge="proposalInfo.proposal_notes.length"
                  class="p-ml-2"
                ></Badge>
              </template>
              <Button
                label="Adicionar Nota"
                icon="pi pi-plus"
                class="p-button-success"
                @click="addNote = true"
              />
              <divider />
              <PrimeVueTimeline
                :value="proposalInfo.proposal_notes"
                align="left"
                class="customized-timeline"
              >
                <template #marker>
                  <span class="custom-marker p-shadow-4 p-mt-3">
                    <i :class="'pi pi-comment'"></i>
                  </span>
                </template>
                <template #content="slotProps">
                  <Card class="p-shadow-4 p-mt-2">
                    <template #title>
                      {{
                        slotProps.item.user_created != null
                          ? slotProps.item.user_created.username
                          : ""
                      }}
                    </template>
                    <template #subtitle>
                      {{ getDateTime(slotProps.item.created_at) }}
                    </template>
                    <template #content>
                      <div v-html="slotProps.item.note"></div>
                      <!-- <div class="p-d-flex p-jc-end">
                        <Button
                          icon="pi pi-trash"
                          class="p-button-danger p-button-sm"
                          v-tooltip="'Apagar Nota'"
                          @click="deleteNote(slotProps)"
                        />
                      </div> -->
                    </template>
                  </Card>
                </template>
              </PrimeVueTimeline>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="p-col-12">
          <Accordion :activeIndex="activeEmailIndex">
            <AccordionTab
              class="accordion-custom"
              v-for="email in proposalInfo.proposal_messages"
              :key="email.id"
            >
              <template #header>
                <div
                  class="p-d-flex p-jc-between"
                  style="width: 100%"
                  @click="tabOpen(email)"
                >
                  <div>
                    <b>{{ email.email_from }}</b>
                  </div>
                  <div>
                    <i
                      class="pi pi-eye-slash p-mr-1"
                      v-tooltip="'Não Lido'"
                      v-if="email.status == 1"
                    ></i>
                    <i
                      class="pi pi-eye p-mr-1"
                      v-tooltip="'Lido'"
                      v-if="email.status == 2"
                    ></i>
                    <i
                      class="pi pi-send p-mr-1"
                      v-tooltip="'Enviado'"
                      v-if="email.status == 3"
                    ></i>
                    <i
                      class="pi pi-paperclip p-mr-1"
                      v-tooltip="'Anexos'"
                      v-if="email.message_attachments.length > 0"
                    ></i>
                    {{ email.received_on }}
                  </div>
                </div>
              </template>
              <div>
                <div class="card p-shadow-3 p-grid" v-if="email.status < 4">
                  <div class="p-md-9 p-col-12">
                    <div>
                      <b>De:</b> {{ email.email_from }} |
                      {{ email.received_on }}
                    </div>
                    <div class="p-mt-1"><b>Para:</b> {{ email.email_to }}</div>
                    <div class="p-mt-1" v-if="email.email_cc != ''">
                      <b>CC:</b> {{ email.email_cc }}
                    </div>
                  </div>
                  <div class="p-col-12 p-md-3 p-d-flex p-ai-end p-flex-column">
                    <div>
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger"
                        v-tooltip="'Eliminar Email'"
                        @click="removeMessage(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text"
                        v-tooltip="'Reencaminhar'"
                        @click="forwardEmail(email)"
                      />
                      <Button
                        icon="pi pi-reply"
                        class="p-button-rounded p-button-text invert"
                        v-tooltip="'Responder'"
                        @click="responseEmail(email)"
                      />
                    </div>
                  </div>
                  <div class="p-col-12">
                    <divider />
                    <div v-html="email.html" style="overflow-x: auto"></div>
                    <div v-if="email.message_attachments.length > 0">
                      <divider />
                      <i class="pi pi-paperclip"></i><b>Anexos:</b>
                      <div>
                        <Button
                          class="p-button-sm p-button-rounded p-ml-1 p-mt-1"
                          v-for="attachment in email.message_attachments"
                          :key="attachment.id"
                          :label="attachment.name"
                          icon="pi pi-file"
                          @click="downloadAttachments(email.id, attachment.id)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import crmService from "../services/crm.service";
// import proposalService from "../services/proposal.service";
// import clientService from "../services/clients.services";
import externalManagerService from "../services/externalManager.service";
import prospectService from "../services/prospect.service";
import messagesService from "../services/crmEmails.service";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
import ChangeCategory from "../components/crm/ChangeCategory.vue";
import ChangePriority from "../components/crm/ChangePriority.vue";
// import SetStatus from "../components/proposal/SetStatus.vue";
import AddClientContact from "../components/proposal/AddClientContact.vue";
import AddNote from "../components/crm/AddNote.vue";
// import RemoveNote from "../components/proposal/DeleteNote.vue";
import ImportProposal from "../components/crm/ImportLeads.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PrimeVueTimeline from "primevue/timeline";
import unidecode from "unidecode";
export default {
  name: "LeadDetail",
  props: ["proposalId"],
  components: {
    PrimeVueTimeline,
    ChangeCategory,
    ChangePriority,
    // SetStatus,
    AddClientContact,
    Loading,
    AddNote,
    // RemoveNote,
    ImportProposal,
  },
  data() {
    return {
      loading: true,
      proposalInfo: null,
      setResposible: false,
      setCategory: false,
      setPriority: false,
      setStatus: false,
      addClientContact: false,
      addNote: false,
      importProposal: false,
      noteToDelete: {
        show: false,
        proporsalId: null,
        noteId: null,
        noteIndex: null,
      },
      activeEmailIndex: 0,
      status: [],
      hasChange: false,
      sellersList: [],
      contactsList: [],
      clientTypeList: [],
      statusList: [],
      emailCompose: {
        show: false,
        header: null,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      },
      closingProbabilityList: [
        { label: "0%", value: 0 },
        { label: "15%", value: 15 },
        { label: "25%", value: 25 },
        { label: "50%", value: 50 },
        { label: "75%", value: 75 },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    this.sellersList = await prospectService.getSellers();
    this.statusList = await crmService.getStatusList();
    this.proposalInfo = await this.getProposalInfo();
    if (!this.proposalInfo.proposal_messages) {
      this.proposalInfo.proposal_messages = [];
    }

    this.loading = false;
  },
  async created() {
    this.loading = true;
    this.contactsList = await externalManagerService.getAll();
    this.clientTypeList = await crmService.getClientTypeList();
    this.loading = false;
  },
  methods: {
    async getProposalInfo() {
      const processedHtml = (email) => {
        let el = new DOMParser().parseFromString(email.body_html, "text/html");
        [...email.message_attachments_inline].forEach((inlineImg) => {
          let image;
          if (inlineImg.content_id) {
            image = el.querySelector(`img[src$='${inlineImg.content_id}' i]`);
          } else {
            image = el.querySelector(`img[alt$='${inlineImg.name}']`);
          }
          if (!image) {
            email.message_attachments.push({
              id: inlineImg.id,
              name: inlineImg.name,
            });
          } else {
            image.src = `data:${inlineImg.mimetype};${inlineImg.encoding},${inlineImg.data}`;
          }
        });
        return el.documentElement.innerHTML;
      };
      let idProposal = this.$route.params.id
        ? this.$route.params.id
        : this.proposalId;
      return crmService.getProposalInfo(idProposal).then((response) => {
        response.proposal_messages = response.proposal_messages.map((v) => ({
          ...v,
          showCompose: false,
          response: {
            to: [],
            cc: [],
            content: "",
            errorStatus: false,
            errorContent: false,
            errorTo: false,
          },
          html: processedHtml(v),
        }));
        return response;
      });
    },
    getDateTime(date) {
      let auxDate = new Date(date);
      return `${getOnlyDate(auxDate)} ${getOnlyTime(auxDate)}`;
    },
    changeCategoryOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.category = response.result.category_id;
        this.proposalInfo.proposal_category = {
          id: response.result.category_id,
          name: response.result.category_name,
        };
      }
      return (this.setCategory = false);
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        this.proposalInfo.priority = response.result.priority_id;
        this.proposalInfo.proposal_priority = {
          priority: response.result.priority_id,
          name: response.result.priority_name,
        };
      }
      return (this.setPriority = false);
    },
    //   changeStatusOk(response) {
    //     if (response.status == "ok") {
    //       this.proposalInfo.status = response.result.status_id;
    //       this.proposalInfo.proposal_status = {
    //         status: response.result.status_id,
    //         name: response.result.status_name,
    //       };
    //     }
    //     return (this.setStatus = false);
    //   },
    //   setStatusOk(response) {
    //     if (response.status == "ok") {
    //       this.proposalInfo.statusName = response.currentStatus;
    //       this.proposalInfo.status = response.currentStatusId;
    //     }
    //     return (this.setStatus = false);
    //   },
    contactAddedOk(response) {
      if (response.status == "ok") {
        this.contactsList.push(response.contact);
        this.proposalInfo.contact_id = response.contact.id;
      }
      return (this.addClientContact = false);
    },
    setMessageStatus(email, status) {
      return messagesService
        .setStatus(email.id, { status_id: status })
        .then((response) => {
          email.status = response.status_id;
          email.message_status.status = response.status_id;
          email.message_status.name = response.status_name;
        });
    },
    removeMessage(email) {
      this.$confirm.require({
        header: "Eliminar Email",
        message: "Tem a certeza que deseja eliminar este email?",
        icon: "pi pi-exclamation-triangle",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        accept: () => {
          return this.setMessageStatus(email, 4);
        },
        reject: () => {},
      });
    },
    downloadAttachments(emailId, attachmentId) {
      return messagesService
        .getAttachment(emailId, attachmentId)
        .then((response) => {
          let linkSource = `data:${response.mimetype};base64,${response.data}`;
          let downloadLink = document.createElement("a");
          let fileName = response.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    tabOpen(email) {
      if (email.status == 1) {
        return this.setMessageStatus(email, 2);
      }
    },
    forwardEmail(email) {
      let cc = [];
      if (email.email_cc.includes(", ")) {
        let ccAux = email.email_cc.replace(/\s/g, "");
        if (ccAux != "") {
          cc = ccAux.split(",");
        }
      }
      if (!email.email_cc.includes("crm@w4msolutions.com")) {
        cc.push("crm@w4msolutions.com");
      }
      return (this.emailCompose = {
        show: true,
        header: "Reencaminhar Email",
        email: email,
        response: {
          to: [],
          cc: cc,
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    responseEmail(email) {
      let cc = [];
      if (email.email_cc != "''") {
        let ccAux = email.email_cc.replace(/\s/g, "");
        if (ccAux != "") {
          cc = ccAux.split(",");
        }
      }
      if (!email.email_cc.includes("crm@w4msolutions.com")) {
        cc.push("crm@w4msolutions.com");
      }
      let to = email.email_from.replace(/\s/g, "");
      return (this.emailCompose = {
        show: true,
        header: "Responder a Email",
        email: email,
        response: {
          to: to.split(", "),
          cc: cc,
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
      });
    },
    cancelSend() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.emailCompose = {
        show: false,
        response: {
          to: [],
          cc: [],
          content: "",
          errorStatus: false,
          errorContent: false,
          errorTo: false,
        },
        email: {},
      });
    },
    sendEmail() {
      let hasError = false;
      if (this.emailCompose.response.content == "") {
        hasError = true;
        this.emailCompose.response.errorContent = true;
      } else {
        this.emailCompose.response.errorContent = false;
      }
      if (this.emailCompose.response["to"].length == 0) {
        hasError = true;
        this.emailCompose.response.errorTo = true;
      } else {
        this.emailCompose.response.errorTo = false;
      }
      if (hasError) {
        return;
      }

      var formData = new FormData();
      formData.append("email_id", this.emailCompose.email.id);
      formData.append("to", this.emailCompose.response["to"]);
      formData.append("cc", this.emailCompose.response.cc);
      formData.append("body", this.emailCompose.response.content);
      let attachments = this.$refs["attachmentInput"];

      if (attachments.files.length > 0) {
        attachments.files.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }
      this.$validator.pause();
      this.$validator.reset();
      this.loading = true;
      return crmService
        .sendMessage(this.proposalInfo.id, formData)
        .then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao enviar o email",
              detail: "",
              life: 3000,
            });
            return (this.loading = false);
          }
          this.$toast.add({
            severity: "success",
            summary: `Email enviado com sucesso.`,
            detail: `O email pode demorar até 5 minutos para aparecer na Lead.`,
            life: 5000,
          });
          this.loading = false;
          return this.cancelSend();
        });
    },
    contactsListLabel(item) {
      let label = `${item.name}`;
      if (item.email != "") {
        label += ` | ${item.email}`;
      }
      if (item.telephone != "") {
        label += ` | ${item.telephone.replace(" ", "")}`;
      }
      if (item.type != "") {
        label += ` | ${item.type}`;
      }
      return label;
    },
    saveProposal() {
      let params = {};
      if (this.proposalInfo.status) {
        params.status = parseInt(this.proposalInfo.status);
      }
      if (this.proposalInfo.closing_probability) {
        params.closing_probability = parseInt(
          this.proposalInfo.closing_probability
        );
      }

      if (this.proposalInfo.client_type) {
        params.client_type = parseInt(this.proposalInfo.client_type);

        if (
          this.proposalInfo.client_type > 1 &&
          this.proposalInfo.contact_id
        ) {
          params.contact_id = this.proposalInfo.contact_id;
        } else if (this.proposalInfo.client_type == 1) {
          if (this.proposalInfo.potential_client_name) {
            params.potential_client_name =
              this.proposalInfo.potential_client_name;
          }
          if (this.proposalInfo.potential_client_person) {
            params.potential_client_person =
              this.proposalInfo.potential_client_person;
          }
          if (this.proposalInfo.potential_client_telephone) {
            params.potential_client_telephone =
              this.proposalInfo.potential_client_telephone;
          }
          if (this.proposalInfo.potential_client_email) {
            params.potential_client_email =
              this.proposalInfo.potential_client_email;
          }
          if (this.proposalInfo.potential_client_comments) {
            params.potential_client_comments =
              this.proposalInfo.potential_client_comments;
          }
          if (this.proposalInfo.competitors) {
            params.competitors = this.proposalInfo.competitors;
          }
        }
      }
      if (this.proposalInfo.cleanSubject) {
        params.cleanSubject =
          this.proposalInfo.cleanSubject;
      }
      if (this.proposalInfo.seller_id) {
        params.seller_id = parseInt(this.proposalInfo.seller_id);
      }

      if (this.proposalInfo.description) {
        params.description = this.proposalInfo.description;
      }

      if (this.proposalInfo.sale_value) {
        params.sale_value = this.proposalInfo.sale_value;
      }
      if (this.proposalInfo.monthly_sales_value) {
        params.monthly_sales_value = this.proposalInfo.monthly_sales_value;
      }
      if (this.proposalInfo.cost) {
        params.cost = this.proposalInfo.cost;
      }

      if (this.proposalInfo.proposal_date) {
        if (this.proposalInfo.proposal_date instanceof Date) {
          params.proposal_date = `${getOnlyDate(
            this.proposalInfo.proposal_date
          )}`;
        } else {
          params.proposal_date = this.proposalInfo.proposal_date;
        }
      }

      if (this.proposalInfo.expected_sale_date) {
        if (this.proposalInfo.expected_sale_date instanceof Date) {
          params.expected_sale_date = `${getOnlyDate(
            this.proposalInfo.expected_sale_date
          )}`;
        } else {
          params.expected_sale_date = this.proposalInfo.expected_sale_date;
        }
      }

      if (this.proposalInfo.expected_installation_date) {
        if (this.proposalInfo.expected_installation_date instanceof Date) {
          params.expected_installation_date = `${getOnlyDate(
            this.proposalInfo.expected_installation_date
          )}`;
        } else {
          params.expected_installation_date =
            this.proposalInfo.expected_installation_date;
        }
      }

      if (this.proposalInfo.next_contact) {
        if (this.proposalInfo.next_contact instanceof Date) {
          params.next_contact = `${getOnlyDate(
            this.proposalInfo.next_contact
          )} ${getOnlyTime(this.proposalInfo.next_contact)}`;
        } else {
          params.next_contact = this.proposalInfo.next_contact;
        }
      }

      return crmService
        .update(this.proposalInfo.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao atualizar a proposta",
              detail: "",
              life: 3000,
            });
          }
          this.hasChange = false;
          return this.$toast.add({
            severity: "success",
            summary: `Proposta actualizada com sucesso`,
            detail: ``,
            life: 3000,
          });
        });
    },
    noteAdded(response) {
      if (response.status == "ok") {
        this.proposalInfo.proposal_notes.unshift(response.note);
      }
      return (this.addNote = false);
    },
    //   deleteNote(noteInfo) {
    //     return (this.noteToDelete = {
    //       proporsalId: this.proposalInfo.id,
    //       noteId: noteInfo.item.id,
    //       noteIndex: noteInfo.index,
    //       show: true,
    //     });
    //   },
    //   deleteNoteCancel() {
    //     return (this.noteToDelete = {
    //       proporsalId: null,
    //       noteId: null,
    //       noteIndex: null,
    //       show: false,
    //     });
    //   },
    //   deleteNoteOk(response) {
    //     if (response.status == "ok") {
    //       this.proposalInfo.proposal_notes.splice(this.noteToDelete.noteIndex, 1);
    //     }
    //     return this.deleteNoteCancel();
    //   },
    importProposalOk(response) {
      if (response.status == "ok") {
        let route = this.$router.resolve({
          path: `/crm/lead/${response.destinationleadId}`,
        });
        window.open(route.href, "_self");
      }
    },
    searchLabelName(info) {
      return `${unidecode(info.name)}`;
    },
    goProposal() {
      let route = this.$router.resolve({
        path: `/proposal-detail/${this.proposalInfo.origin_id}`,
      });
      window.open(route.href, "_blank");
    },
    goSurvey() {
      let route = this.$router.resolve({
        path: `/pat-details/${this.proposalInfo.origin_id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}
.error {
  color: #dc3545 !important;
}
.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}

.invert {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.p-multiselect-label {
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}

.custom-marker {
  display: -webkit-;
  display: -ms-flexbox;
  display: flex;
  width: 2rem;
  height: 2rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #ff4910;
}
.p-timeline-event-opposite {
  display: none !important;
}
.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.p-chips-token {
  background-color: #fff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #3498db !important;
  margin-bottom: 2px;
}
</style>
